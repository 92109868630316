import React from "react"
import { styled, Headline, Animate, Grid, Button } from "unikit"
import { ImageBackground } from "react-native"
import { Link } from "gatsby"

const Box = styled.View(({ theme, bg }) => ({
  display: "flex",
  width: "100%",
  height: "50vh",
  minHeight: "50vh",
  backgroundColor: bg ? "#CCC" : "surface",
  justifyContent: "center",
}))

export default ({
  title,
  text,
  delay = 100,
  image,
  logo,
  reverse,
  color,
  link,
}) => {
  const Image = (
    <Box bg>
      <Animate
        style={{
          width: "100%",
          height: "100%",
        }}
        delay={delay}
        onVisible
      >
        <ImageBackground
          source={image}
          alt="Three George Düsseldorf"
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0,0,0,0.15)",
              zIndex: 0,
            }}
          />
          {logo ? (
            <img
              src={logo}
              alt={title}
              style={{
                width: "auto",
                height: 150,
                position: "relative",
                zINdex: 10,
              }}
            />
          ) : null}
        </ImageBackground>
      </Animate>
    </Box>
  )
  return (
    <Box style={{ width: "100%", marginTop: 100 }}>
      {Image}
      <Headline level={3} animate>
        {title}
      </Headline>
    </Box>
  )
}
