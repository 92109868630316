import React from "react"
import { View, Text } from "react-native"
import { Headline, Animate, styled, Grid } from "unikit"

import { Layout } from "../components"

import Fire from "../images/fire.gif"
import Smoke from "../images/smoke.png"

const Section = styled.View(({ height, background }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: height || "100vh",
  backgroundColor: background || "#231c20",
  paddingVertical: "7rem",
}))

const Wrapper = styled.View(({ theme }) => ({
  margin: "0 auto",
  maxWidth: theme.wrapperWidth,
  width: "80%",
}))

export default ({ pageContext }) => (
  <Section>
    <Animate
      delay={500}
      style={{ position: "absolute", left: 0, top: "50%", right: 0 }}
    >
      {console.log({ pageContext })}
      <img
        src={Fire}
        alt="Logo"
        style={{
          margin: "0 auto",
          width: "auto",
          height: "90vh",
          marginTop: "-45vh",
        }}
      />
    </Animate>
    <Animate
      from={{ opacity: 0 }}
      style={{ position: "absolute", left: 0, top: 0 }}
    >
      <img
        src={Smoke}
        alt="Logo"
        style={{
          width: "100vw",
          height: "100vh",
          backgroundSize: "cover",
        }}
      />
    </Animate>

    <Headline
      style={{
        color: "#FFF",
        position: "relative",
        zIndex: 100,
        textAlign: "center",
        maxWidth: 700,
        lineHeight: "4rem",
      }}
      animate
      // animateType="char"
    >
      Digital Design & Tech Agency in Duesseldorf
    </Headline>
  </Section>
)
