import React from "react"
import { styled, Headline, Animate, Grid, Button } from "unikit"
import { ImageBackground } from "react-native"
import { Link } from "gatsby"

const Box = styled.View(({ theme, bg }) => ({
  display: "flex",
  width: "100%",
  height: bg ? "50vh" : "auto",
  minHeight: "50vh",
  backgroundColor: bg ? "#CCC" : "surface",
  alignItems: "center",
  justifyContent: "center",
}))

const Text = styled.Text(({ theme }) => ({
  fontSize: theme.fontSize.p,
  textAlign: "center",
  lineHeight: theme.fontSize.p * 1.4,
  fontWeight: 100,
  marginTop: 30,
  opacity: 0.6,
}))

export default ({ title, text, delay, image, logo, reverse, color, link }) => {
  const Image = (
    <Box bg>
      <ImageBackground
        source={image}
        alt="Three George Düsseldorf"
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          padding: 40,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.15)",
            zIndex: 0,
          }}
        />
        {logo ? (
          <img
            src={logo}
            alt={title}
            style={{
              width: "auto",
              height: 150,
              position: "relative",
              zINdex: 10,
            }}
          />
        ) : null}
      </ImageBackground>
    </Box>
  )
  return (
    <Animate
      style={{
        width: "100%",
        height: "100%",
      }}
      onVisible
      delay={delay}
    >
      <Grid maxRows={2} gap={0} min={700}>
        {!reverse ? Image : null}
        <Box style={{ paddingHorizontal: "10%", paddingVertical: "7rem" }}>
          <Headline
            level={2}
            style={{
              color: "dark",
              textAlign: "center",
            }}
            animate
          >
            {title}
          </Headline>
          <Text>{text}</Text>
          {link && (
            <Button
              onPress={() => {
                window.open(link, "_blank")
              }}
              color={color}
              style={{ marginTop: 20 }}
            >
              Mehr
            </Button>
          )}
        </Box>
        {reverse ? Image : null}
      </Grid>
    </Animate>
  )
}
